


<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">用户消费明细</div>
      <div class="formDiv" style="width: 77vw; margin-left: 10vw">
        <el-form label-position="right" label-width="75px">
          <el-row :gutter="10">
            <el-col :span="5">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="充电类型:">
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择充电类型"
                  clearable
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="5">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="时间:" class="time_pick">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div
                class="BtnSearch"
                style="margin-top: 5px"
                @click="searchBtn()"
              >
                查询
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="540px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="订单号" prop="order_id" width="110" />
          <el-table-column label="用户手机号" prop="phone" width="110" />
          <el-table-column label="设备号" prop="device_id" width="130" />
          <el-table-column label="站点名称" prop="station_name" width="160" />
          <el-table-column label="充电类型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <el-table-column label="端口号" prop="socket" />
          <el-table-column
            label="充电时长(分钟)"
            prop="cost_time"
            width="120"
            :formatter="rounding"
          />
          <!-- <el-table-column label="充电状态" prop="status" /> -->
          <el-table-column
            label="开始时间"
            prop="start_time"
            :formatter="formatDate"
            width="160"
          />
          <el-table-column
            label="结束时间"
            prop="end_time"
            :formatter="formatDate"
            width="160"
          />
          <el-table-column
            label="停止原因"
            prop="stop_charge_status"
            width="200"
          />
          <el-table-column
            label="消费金额(元)"
            prop="cost_money"
            :formatter="rounding"
             width="130"
          />
          <el-table-column label="付款方式" prop="pattern_of_payment" />
          <el-table-column label="所属运营商" prop="username" width="100" />
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :small="small"
          layout="total, slot, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
        >
          <template #default>
            <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
          </template>
        </el-pagination>
      </div>
    </div>

    <!-- </div> -->
  </div>
</template>
  <script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { user_consumption_detail, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";

import ProvinceCityCountry from "../../assets/json/address";
export default {
  name: "ExpendDetail",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime: ["", ""],
      },
      isadmin: false,
      users: [],
      tableData: [],
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,

      loading: false,
      provinceData: ProvinceCityCountry.address,
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };
    const searchBtn = () => {
      data.currentPage= 1;
      getList();
    };

    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.orderTime) {
        stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : "";
        endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : "";
      } else if (
        data.FormData.orderTime === null ||
        data.FormData.orderTime === undefined ||
        data.FormData.orderTime === ""
      ) {
        stDate = "";
        endDate = "";
      }
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
        username: data.FormData.username,
        phone: data.FormData.phone,
        type_id: data.FormData.type_id,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        page: data.currentPage,
      };
      data.loading = true;
      console.log(dataa, "获取列表dataa数据");
      user_consumption_detail(dataa).then((res) => {
        if (res) {
          console.log(res, "消费明细列表");
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data ;
            data.tableData.forEach((a)=>{
              a.cost_money = Number(a.cost_money )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            })
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    // const getTime = () => {
    //   var aData = new Date();
    //   const nowdate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
    //   data.FormData.orderTime[0] = nowdate
    //   data.FormData.orderTime[1] = ''
    //   console.log(data.FormData.orderTime,'data.order_timedata.order_time');
    // }
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList()
    };
    onMounted(() => {
      // getTime()
      getList();
      getUsername();
    });
    return {
      ...toRefs(data),
      formatDate,
      searchBtn,
      rounding,
      getList,
      // getTime,
      getUsername,
      handleCurrentChange,
    };
  },
};
</script>
  <style scoped>
.bottomDiv {
  height: 100%;
}
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
  width: 100%;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
</style>